<template>
  <div class="navbar-container main-menu-content d-flex justify-content-center align-items-center animate__animated animate__fadeInLeft">
    <horizontal-nav-menu-items :items="menus" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  computed: {
    ...mapGetters([
      'menus',
    ]),
  },
  setup() {
    return {
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
